<template>
  <div class="page">
    <div class="search-box section">
      <el-form
          ref="query"
          :inline="true"
          :model="query"
          class="demo-form-inline"
          size="medium"
      >
        <el-form-item prop="phoneNumber">
          <el-input
              v-model="query.phoneNumber"
              clearable
              placeholder="请输入手机号"
              prefix-icon=""
              style="width: 260px"
          ></el-input>
        </el-form-item>
        <el-form-item prop="VehicleId">
          <NumplateSuggest v-model="query.VehicleId" style="width: 260px"/>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="doSearch">搜索</el-button>
          <el-button @click="resetQueryForm">重置</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div class="content-box section">
      <div class="op-box">
        <div class="op-content">
          共<span class="total-size">{{ pagination.totalSize }}</span
        >条信息
        </div>
      </div>
      <!-- <div class="op-extra">
          <icon-button icon="share" title="导出" @click.native="exportData" />
        </div> -->
      <div>
        <el-table
            v-loading="isLoading"
            :data="dataList"
            border
            class="common-table"
            size="medium"
            style="width: 100%"
        >
          <el-table-column
              align="center"
              label="序号"
              prop="serialNumber"
          >
          </el-table-column>

          <el-table-column align="center" label="用户信息" width="504">
            <el-table-column label="车牌号" width="252"></el-table-column>
            <el-table-column label="手机号" width="252"></el-table-column>
          </el-table-column>

          <el-table-column
              align="center"
              label="序号"
              prop="欠费信息"
              width="840"
          >
            <el-table-column label="欠费金额(元)" width="280"></el-table-column>
            <el-table-column label="欠费笔数" width="280"></el-table-column>
            <el-table-column label="逾期时长(天)" width="280"></el-table-column>
          </el-table-column>
        </el-table>
      </div>
      <div class="pagination-box">
        <el-pagination
            :current-page="pagination.pageNo"
            :page-size="pagination.pageSize"
            :total="pagination.totalSize"
            background
            layout="sizes, prev, pager, next, jumper"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>


<script>
import BaseMixin from "@/mixins/base";
import NumplateSuggest from "@/components/NumplateSuggest";

export default {
  name: "vehicle_arrears",
  mixins: [BaseMixin],
  components: {
    NumplateSuggest,
  },
  data() {
    return {
      query: {
        VehicleId: "",
        phoneNumber: "",
      },
      isLoading: false,
      dataList: [],
    };
  },
  methods: {
    //重置查询条件
    resetQueryForm() {
      this.$refs.query.resetFields();
      this.doSearch();
    },
    //查询列表数据
    doSearch() {
      this.pagination.pageNo = 1;
      this.pagination.pageSize = 10;
      this.search();
    },
    async search() {
      //   this.isLoading = true;
      //   const params = this.paramFormat(this.query);
      //   const res = await getParkingOrderByPage(params);
      //   this.isLoading = false;
      //   if (res && res.code === 30 && res.result) {
      //     const returnObject = res.returnObject;
      //     this.dataList = returnObject.list;
      //     this.pagination.totalSize = returnObject.count;
      //     this.pagination.totalPages = returnObject.totalPage;
      //     this.total = returnObject.count;
      //   }
    },
  },
};
</script>

<style lang="less" scoped>
</style>
